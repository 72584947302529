/*
 * File: vuetify.ts
 * Description: Nuxt plugin to setup Vuetify.
 */

// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css';

import 'vuetify/styles';
import {createVuetify} from 'vuetify';

export default defineNuxtPlugin((app) => {
    const vuetify = createVuetify({

    });
    app.vueApp.use(vuetify);
});

import { default as confirmRArZzVAbrEMeta } from "/build/pages/confirm.vue?macro=true";
import { default as index8ngV6AiZ7FMeta } from "/build/pages/dashboard/index.vue?macro=true";
import { default as _91id_93OY7FYniENBMeta } from "/build/pages/dashboard/locations/edit/[id].vue?macro=true";
import { default as indexkQjUK3NbqiMeta } from "/build/pages/dashboard/locations/index.vue?macro=true";
import { default as newA8nNx1svC0Meta } from "/build/pages/dashboard/locations/new.vue?macro=true";
import { default as indexWZQItFPRvjMeta } from "/build/pages/dashboard/studies/patients/index.vue?macro=true";
import { default as _91id_93eL1ugg2SRNMeta } from "/build/pages/dashboard/studies/visits/edit/[id].vue?macro=true";
import { default as _91id_93NpiJUn7v8xMeta } from "/build/pages/dashboard/studies/visits/history/[id].vue?macro=true";
import { default as indexP8Jki4ISfUMeta } from "/build/pages/dashboard/studies/visits/index.vue?macro=true";
import { default as newtYAgtKyRUaMeta } from "/build/pages/dashboard/studies/visits/new.vue?macro=true";
import { default as _91id_93iSUdgYx0wVMeta } from "/build/pages/dashboard/users/edit/[id].vue?macro=true";
import { default as indexG4JEpnlJ7mMeta } from "/build/pages/dashboard/users/index.vue?macro=true";
import { default as newrWBWyfiFgnMeta } from "/build/pages/dashboard/users/new.vue?macro=true";
import { default as index2yY1hMMS9YMeta } from "/build/pages/index.vue?macro=true";
import { default as loginSPHB0sFF0AMeta } from "/build/pages/login.vue?macro=true";
import { default as new_45passwordQo7VhalIesMeta } from "/build/pages/new-password.vue?macro=true";
import { default as password_45resetjGxOzhmcjBMeta } from "/build/pages/password-reset.vue?macro=true";
export default [
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/build/pages/confirm.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index8ngV6AiZ7FMeta || {},
    component: () => import("/build/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-locations-edit-id",
    path: "/dashboard/locations/edit/:id()",
    meta: _91id_93OY7FYniENBMeta || {},
    component: () => import("/build/pages/dashboard/locations/edit/[id].vue")
  },
  {
    name: "dashboard-locations",
    path: "/dashboard/locations",
    meta: indexkQjUK3NbqiMeta || {},
    component: () => import("/build/pages/dashboard/locations/index.vue")
  },
  {
    name: "dashboard-locations-new",
    path: "/dashboard/locations/new",
    meta: newA8nNx1svC0Meta || {},
    component: () => import("/build/pages/dashboard/locations/new.vue")
  },
  {
    name: "dashboard-studies-patients",
    path: "/dashboard/studies/patients",
    meta: indexWZQItFPRvjMeta || {},
    component: () => import("/build/pages/dashboard/studies/patients/index.vue")
  },
  {
    name: "dashboard-studies-visits-edit-id",
    path: "/dashboard/studies/visits/edit/:id()",
    meta: _91id_93eL1ugg2SRNMeta || {},
    component: () => import("/build/pages/dashboard/studies/visits/edit/[id].vue")
  },
  {
    name: "dashboard-studies-visits-history-id",
    path: "/dashboard/studies/visits/history/:id()",
    meta: _91id_93NpiJUn7v8xMeta || {},
    component: () => import("/build/pages/dashboard/studies/visits/history/[id].vue")
  },
  {
    name: "dashboard-studies-visits",
    path: "/dashboard/studies/visits",
    meta: indexP8Jki4ISfUMeta || {},
    component: () => import("/build/pages/dashboard/studies/visits/index.vue")
  },
  {
    name: "dashboard-studies-visits-new",
    path: "/dashboard/studies/visits/new",
    meta: newtYAgtKyRUaMeta || {},
    component: () => import("/build/pages/dashboard/studies/visits/new.vue")
  },
  {
    name: "dashboard-users-edit-id",
    path: "/dashboard/users/edit/:id()",
    meta: _91id_93iSUdgYx0wVMeta || {},
    component: () => import("/build/pages/dashboard/users/edit/[id].vue")
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: indexG4JEpnlJ7mMeta || {},
    component: () => import("/build/pages/dashboard/users/index.vue")
  },
  {
    name: "dashboard-users-new",
    path: "/dashboard/users/new",
    meta: newrWBWyfiFgnMeta || {},
    component: () => import("/build/pages/dashboard/users/new.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/build/pages/login.vue")
  },
  {
    name: "new-password",
    path: "/new-password",
    component: () => import("/build/pages/new-password.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/build/pages/password-reset.vue")
  }
]